import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(@Inject(PLATFORM_ID) public platformId: object) {
	this.testBrowser = isPlatformBrowser(this.platformId)
 }

  testBrowser: boolean

  public setItem(name : string, data : any){
	if (this.testBrowser){
		sessionStorage.setItem(name, data);
	}
  }

  public setArrayItem(name : string, data : any){
	if (this.testBrowser){
		if(data == undefined){
			data = null;
		}
		sessionStorage.setItem(name, JSON.stringify(data));
	}
  }

  public getItem(name : string): string | null {
	let token = null;
	if (this.testBrowser) {		
		token = sessionStorage.getItem(name);
	}
    return token;
  }

  public getArrayItem(name : string){
	let object = {};
	if (this.testBrowser) {				
		let rawObject = sessionStorage.getItem(name);
		if(rawObject){
			object=JSON.parse(rawObject);
		}
	}
    return object;
  }

  public getListItem(name : string){
	let object = [];
	if (this.testBrowser) {				
		let rawObject = sessionStorage.getItem(name);
		if(rawObject){
			object=JSON.parse(rawObject);
		}
	}
    return object;
  }

  public removeItem(name : string){
	if (this.testBrowser) {		
		sessionStorage.removeItem(name);
	}
  }

  public clear(){
	sessionStorage.clear();
  }
}
