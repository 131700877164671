<div id="footer" style="margin-top: 10px; font-size: initial; height:5em;">
	<div class="copyright">
    	<p>{{ 'txt.reservedRights' | translate }} &copy; {{ 'txt.companyName' | translate }} {{ version }}</p>
   		<ul class="menu">
   			<li><span (click)="setLang('fr')" class="clickable">{{ 'txt.french' | translate }}</span> | 
   				<span (click)="setLang('en')" class="clickable">{{ 'txt.english' | translate }}</span> | 
   				<a href="https://www.ofys.ca/fr/sofy.html" target="_blank">{{ 'txt.guide' | translate }}</a> |
   				<span [innerHTML]="termsAndPrivacy | translate"></span>
   			</li>
   		</ul>
	</div>
</div>
