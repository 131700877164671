import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginService } from '../loggedout/login/login.service';
import { StorageService } from '../storage.service';

@Injectable()
export class SessionInterceptor {

  constructor(private storage : StorageService, private router: Router, private service: LoginService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
	let lang = this.storage.getItem("lang");
	if(lang === null){
		lang = "fr"
	}
	req = req.clone({
	    withCredentials: true,
		headers: req.headers.append(
			"Accept-Language", lang
		)
	});
	
	return next.handle(req).pipe(
		tap(null, error=>{
			if(error.status === 401){
				if(req.url.includes("rvsp")){
					this.storage.removeItem("authToken");
					this.router.navigate(["/rvsp/auth"])
				} else {
					this.service.authError = "error.msg.invalidToken";
					this.router.navigate(["logout"])
				}
			}
		})
	)
  }

}