import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config-service.service';
import { HandleError } from 'src/app/include/util/function';
import { Clinic } from '../clinic';
import { Dependent } from '../dependent';
import { SofyAppointment } from '../sofy-appointment';
import { SofyAppointmentSpaceResult } from '../sofy-appointment-space-result';
import { User } from '../user';
import { ClinicProfResult } from './available/clinic-prof-result';
import { FindAvailableAppointmentCriteria } from './find-available-appointment-criteria';
import { HistoryResult } from './history/history-result';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  clinics: Clinic[] = []
  hashProfName?: {[key: string] : string};
  hashClinicProf?: {[key: string] : {[key: string] : string}};
  criteria?: FindAvailableAppointmentCriteria;
  reserved! : SofyAppointment;
  user!: User;
  dependentList!: Dependent[];
  currentDependent?: Dependent;

  dependentChangeObserver: Subject<Dependent> = new Subject<Dependent>();

  private baseUrl;

  constructor(private http: HttpClient, private config: AppConfigService) { 
	this.baseUrl = this.config.getSofyUrl();
	this.dependentChangeObserver.subscribe((value) => {
        this.currentDependent = value
    });
  }

  changeDependent(dep: Dependent){
	this.dependentChangeObserver.next(dep);
  }

  removeDependent(dep: Dependent){
	const index: number = this.dependentList.indexOf(dep);
    if (index !== -1) {
        this.dependentList.splice(index, 1);
    }
  }

  updateDependentList(dep: Dependent){
	let index = this.dependentList.findIndex(e => e.patientId == dep.patientId)
	this.dependentList[index].hasAccess = dep.hasAccess;
	this.dependentList[index].hin = dep.hin;
	this.dependentList[index].locked = dep.locked;
	this.dependentList[index].birthDate = dep.birthDate;
	this.dependentList[index].age = dep.age;
	return this.dependentList[index];
  }

  getDependent(){
	let url = this.baseUrl + "user/dependents";
	return this.http.get<Dependent[]>(url)
		.pipe(catchError(HandleError),
			tap(response =>{
				this.dependentList = response;
				return response;
			})
		);
  }
  updateDependentAccess(dependent : Dependent){
	let url = this.baseUrl + "user/updateAccessDependent";
	return this.http.post<Dependent>(url, dependent)
		.pipe(catchError(HandleError),
			tap(response =>{
//				this.dependentList = response;
				return response;
			})
		);
  }
  removeDependentAccess(dependent : Dependent){
	let url = this.baseUrl + "user/removeDependentAccess";
	return this.http.post(url, dependent)
		.pipe(catchError(HandleError),
			tap(response =>{
//				this.dependentList = response;
				return response;
			})
		);
  }
  lockDependentAccess(dependent : Dependent){
	let url = this.baseUrl + "user/lockDependentAccess";
	return this.http.post<Dependent>(url, dependent)
		.pipe(catchError(HandleError),
			tap(response =>{
//				this.dependentList = response;
				return response;
			})
		);
  }

  getClinicProfUser(){
	let url = this.baseUrl + "appointment/available";
	return this.http.get<ClinicProfResult>(url)
		.pipe(catchError(HandleError),
			tap(response =>{
				this.clinics = response.clinics;
				this.hashClinicProf = response.hashClinicProf;
				this.hashProfName = response.hashProfName;
				this.user = response.user;
			})
		);
  }

  getAppSpace(criteria : FindAvailableAppointmentCriteria){
	let url = this.baseUrl + "appointment/available";
	criteria.dependent = this.currentDependent;
	return this.http.post<SofyAppointmentSpaceResult[]>(url, criteria)
		.pipe(catchError(HandleError),
			tap(response =>{
				
			})
		);
  }

  reserveApp(space: SofyAppointmentSpaceResult, raisonVisite: string, perName: string){
	let url = this.baseUrl + "appointment/reserve";
	let criteria = {
		"space" : space,
		"raisonVisite" : raisonVisite,
		"perName" : perName,
		"dependent": this.currentDependent
	}
	return this.http.post<SofyAppointment>(url, criteria)
		.pipe(catchError(HandleError),
			tap(response =>{
				this.reserved = response;
			})
		);
  }

  cancelReserve(clinicId : number){
	let url = this.baseUrl + "appointment/cancelreservation";
	return this.http.get(url, {params : { clinicId }} )
		.pipe(catchError(HandleError),
			tap(response => {
				
			})
		);
  }

  findHistory(){
	let url = this.baseUrl + "appointment/history";
	let dependent = this.currentDependent;
	return this.http.post<HistoryResult>(url, dependent)
		.pipe(catchError(HandleError),
			tap(response =>{
				
			})
		);
  }

  clearDependent(){
	this.dependentList = [];
	this.currentDependent = undefined;
  }

}

