import { HttpErrorResponse } from "@angular/common/http";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { throwError } from "rxjs";

//Error handler, doit être gérer différent pour un retour de type string
export function HandleError(httpError: HttpErrorResponse) {
    let message:string = '';

    if (httpError.error instanceof ProgressEvent) {
      console.log('in progress event')
      message = "errors.network.error";
    }
    else {
      message = httpError.error.message;
      console.error(
        `Backend returned code ${httpError.status}, ` +
        `body was: ${httpError.message}`);
    }
	if(message === undefined){
		message = httpError.error;
	}
    return throwError(message);
}

export function HandleErrorText(httpError: HttpErrorResponse) {
    let message:string = '';

    if (httpError.error instanceof ProgressEvent) {
      console.log('in progress event')
      message = "errors.network.error";
    }
    else {
      message = httpError.error.message;
      console.error(
        `Backend returned code ${httpError.status}, ` +
        `body was: ${httpError.message}`);
    }

	var json = JSON.parse(httpError.error);
	message = json.message;
    return throwError(message);
}

//gestion des espace blanc
export function removeWhiteSpace(input : any){
	const start = input.selectionStart;
	const end = input.selectionEnd;
	const nowhitespace = input.value;
	const oldlenght = nowhitespace.length - 1;
	input.value = nowhitespace.replace(/\s/g, "");
	const newlenght = input.value.length - 1;
	let offset = newlenght - oldlenght;
	setTimeout(() =>{
		input.selectionStart = +start + (start + offset < 0 ? 0 : offset);
  		input.selectionEnd = +end + (end + offset < 0 ? 0 : offset);
	})
}

export function addWhiteSpace(input : any){
	let pos = input.selectionStart;
	let ele = input.value;
	if (ele.length){
		if (ele.toString().length <= pos){
			pos = pos +1;
		}
		ele = ele.split(/[ \/]/g).join('');
		let finalEle = "";
		if (ele.length){
			finalEle = ele.match(/.{1,3}/g).join(' ');
		}
		input.value = finalEle;
		input.setSelectionRange(pos , pos);
	}
}

export function isMobile(){
	var ua = navigator.userAgent;
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
}

//Embellie le numéro de téléphone
export function transformTel(tel : string){
	if(tel.length == 10){
		return tel.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3')
	}else{
		return tel;
	}
}

//Decode un byte64 en UTF-8
export function b64DecodeUnicode(str : string) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
}

//Regarde si les des valeurs sont identiques
export function checkSameValidator(object: string, validate: string) : ValidatorFn{
	return (fg: AbstractControl): ValidationErrors | null => {
		let check = fg.get(object)?.value;
		let validateCheck = fg.get(validate)?.value;
		return check === validateCheck ? null : {mustMatch: true};
		
	}
}

//Regarde la liste de fields passer en paramètre et s'assure qu'au moins un est non vide
export function atLeastOneValidator(...fields: string[]) {
  return (fg: AbstractControl): ValidationErrors | null => {
    return fields.some(fieldName => isFieldEmpty(fieldName, fg))
      ? null
      : ({ atLeastOne: 'At least one field has to be provided.' } as ValidationErrors);
  };
}

export function convert(str : string){
	return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const isFieldEmpty = (fieldName: string, fg: AbstractControl) => {
    const field = fg.get(fieldName)?.value;
    if (typeof field === 'number') { return field && field >= 0 ? true : false; }
    if (typeof field === 'string') { return field && field.length > 0 ? true : false; }
	else {return null}
};
