import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config-service.service';
import { HandleError } from 'src/app/include/util/function';
import { Usersignup } from './usersignup';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  private baseUrl;

  constructor(private http: HttpClient, private config: AppConfigService) {
	this.baseUrl = this.config.getSofyUrl() + "signup";
  }

  signup(model: Usersignup){
	let url = this.baseUrl;
	return this.http.post(url, model)
		.pipe(catchError(HandleError),
			tap(response =>{
				sessionStorage.setItem("user", model.login);
			})
		);
  }
}
