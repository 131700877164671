import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { removeWhiteSpace } from 'src/app/include/util/function';
import { emailRegex, namRegex } from 'src/app/include/util/global-variable';
import { RecoverService } from './recover.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.css']
})
export class RecoverComponent implements OnInit {

  error = "";
  msg = "";

  loading: boolean = false;

  faSpinner = faSpinner;

  constructor(private service: RecoverService, private router: Router) { }

  ngOnInit(): void {
  }

   frm = new FormGroup({
	email : new FormControl("", [Validators.required, Validators.pattern(emailRegex)]),
	nam : new FormControl("", [Validators.required, Validators.pattern(namRegex)])
  })

  get email(){
	return this.frm.get("email");
  }

  get nam(){
	return this.frm.get("nam");
  }

  onSubmit(){
	this.error="";
	this.loading = true
	this.service.recoverPassword(this.email!.value, this.nam!.value).subscribe(
		data =>{
			this.router.navigateByUrl('/login', {state: {message : "article.info.pswdRetrevalMailSent"}});
		}, error=> {
			this.loading = false
			this.error = error;
		}
	);
		
  }

  removeWhiteSpace(input : any){
	removeWhiteSpace(input)
  }

}