<app-loggedoutheader></app-loggedoutheader>
<div id="container" class="container">
	<div id="page" class="row">
		<form [formGroup]="signUpForm" #formDir="ngForm" class="form-horizontal card frm" (ngSubmit)="onClickSubmit()">
			<div class="col-12" style="padding-top: 5px">
				<a href="https://www.ofys.ca/fr/sofy.html" target="_blank" style="white-space: normal;" class="btn btn-default btn-lg guide" >{{ 'txt.guide.firsttime' | translate }}</a>
			</div>
			<div class="form-group  em">
				<div *ngIf="error" class="alert alert-danger col-12">{{ error | translate }}</div>
			</div>
			<label class="col-12">{{'txt.lbl.email' | translate }}</label>
			<label class="col-12" style="font-size: 1.1em;font-style: italic;">{{'txt.lbl.email.info' | translate }}</label>
			<div class="col-12 form-div" id="emailParent">
				<input class="form-control" id="email" formControlName="email" type="email" #email oninput="this.value = this.value.toLowerCase()"/>
<!-- 				<div *ngIf="email!.errors && (email!.dirty || email!.touched)" class="text-danger">{{ 'errors.invalidEmail' | translate }}</div> -->
				<div *ngIf="signUpForm.controls['email'].invalid && (signUpForm.controls['email'].dirty || signUpForm.controls['email'].touched)" class="text-danger">
					{{ 'errors.invalidEmail' | translate }}
				</div>
			</div>
			<label class="col-12">{{'txt.lbl.nam' | translate }}</label>
			<div class="col-12 form-div" id="namParent">
				<input id="nam" maxlength="12" class="form-control" formControlName="nam" #nam (input)="nam.value = nam.value.toUpperCase()"/>
				<div *ngIf="signUpForm.controls['nam'].invalid && (signUpForm.controls['nam'].dirty || signUpForm.controls['nam'].touched)" class="text-danger">
					{{ 'errors.jsnamInvalidChars' | translate }}
				</div>
			</div>
			
			<hr/>
			<div class="col-12">
				<label for="login1" class="required">{{'txt.lbl.SignupId' | translate }}</label>
				<label style="font-size: 1.1em;font-style: italic;">{{'txt.lbl.loginId.info' | translate }}</label>
			</div>
			<div class="col-12" id="login1Parent">
				<input class="form-control" id="login1" type="text" formControlName="login" />
				<div *ngIf="login!.errors && (login!.dirty || login!.touched)" class="text-danger">{{ 'errors.jslogin1InvalidChars' | translate }}</div>
			</div>
			<label for="password" class="col-12">{{'txt.lbl.password' | translate }}</label>
			<div class="col-12 form-div">
				<fa-icon id="btnPasswordHelp" class="icon" (click)="openModal()" data-target="#myModal" [icon]="faQuestion"></fa-icon>
				<input class="form-control" id="newPassword1" type="password" formControlName="password" autocomplete="new-password"/>
				<div *ngIf="password!.errors && (password!.dirty || password!.touched)" class="text-danger">{{ 'user.modify.invalidPassword' | translate }}</div>
			</div>
			<label for="passwordConfirmation" class="col-12">{{'user.modify.confirm' | translate }}</label>
			<div class="col-12 form-div" id="passwordConfirmationParent">
				<input class="form-control" id="passwordConfirmation" type="password" formControlName="passwordValidation"/>
				<div *ngIf="passwordValidation!.errors && (passwordValidation!.dirty || passwordValidation!.touched)" class="text-danger"></div>
			</div>
			<div class="col-12 form-div">
				<input type="checkbox" [checked]="false" class="mobile-cb" formControlName="termsAndPrivacy">
				<span [innerHTML]="termsAndPrivacy | translate"></span>
				<div *ngIf="signUpForm.controls['termsAndPrivacy'].invalid && (signUpForm.controls['termsAndPrivacy'].dirty || signUpForm.controls['termsAndPrivacy'].touched)" class="text-danger">
					{{ 'errors.acceptTermsAndPrivacy' | translate }}
				</div>
			</div>
			<div class="col-12" style="padding-top: 5px">
				<button class="btn btn-submit btn-lg pull-right" style="margin-left: 3px;">
					{{ 'txt.btn.submit.create.account' | translate }}
				</button>
				<a routerLink="/login" class="btn pull-right btn-default btn-lg">{{ 'txt.btn.cancel' | translate }}</a>
			</div>
		</form>
	</div>
</div>

<app-loggedoutfooter></app-loggedoutfooter>
