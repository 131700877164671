<app-loggedoutheader></app-loggedoutheader>
<div id="container" class="container">
	<div id="page">
		<div class="card frm" >
			<form class="form-horizontal" modelAttribute="model" id="forgottenForm" [formGroup]="frm" (ngSubmit)="onSubmit()">
				<div class="form-group">
					<div *ngIf="error" class="alert alert-danger col-lg-12">{{ error | translate }}</div>
					<div *ngIf="msg" class="alert alert-success col-lg-12">{{ msg | translate }}</div>
				</div>

				<div class="form-group">
					<div class="alert alert-info">
						<p style="padding: 7px; margin: 0;">{{ "txt.info.completeRecup" | translate }}</p>
					</div>
				</div>

				<div class="form-group" style="margin-top:15px;">
					<label class="col-xs-12">{{ "txt.lbl.email" | translate }}</label>
					<div class="col-xs-12  for-div" style="margin-top:15px;">
						<input path="email" class="form-control" formControlName="email" #email oninput="this.value = this.value.toLowerCase()"/>
<!-- 						<form:errors path="email" class="text-danger"></form:errors> -->
					</div>
				</div>

				<div class="form-group" style="margin-top:35px;">
					<label class="col-xs-12">{{ "txt.lbl.nam" | translate }}</label>
					<div class="col-xs-12 form-div" style="margin-top:15px;">
						<input class="form-control" (keyup)="removeWhiteSpace(nam)" #nam maxlength="12" formControlName="nam" oninput="this.value = this.value.toUpperCase()"/>
						<div *ngIf="frm.controls['nam'].invalid && (frm.controls['nam'].dirty || frm.controls['nam'].touched)" class="text-danger">
							{{ 'errors.jsnamInvalidChars' | translate }}
						</div>
					</div>
				</div>

				<div class="form-group">
						<div class="form-div col-xs-12" style="text-align: right;">
							<a routerLink="/login" class="btn btn-default" style="margin-right:15px;">{{ "txt.btn.cancel" | translate }}</a>
							<button class="btn btn-submit" type="submit" [disabled]="loading">
								{{ "txt.btn.submit" | translate }}
								<fa-icon [icon]="faSpinner" [pulse]="true" class="mr-3" *ngIf="loading"></fa-icon>
							</button>				
						</div>
				</div>

			</form>
		</div>
	</div>
</div>
<app-loggedoutfooter></app-loggedoutfooter>