import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordModalComponent } from 'src/app/include/password-modal/password-modal.component';
import { checkSameValidator } from 'src/app/include/util/function';
import { emailRegex, loginRegex, namRegex, passwordRegex } from 'src/app/include/util/global-variable';
import { SignupService } from './signup.service';
import { Usersignup } from './usersignup';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

//  signUpForm!: FormGroup;

  error = "";
  termsAndPrivacy = "txt.lbl.termsAndPrivacy";

  user: Usersignup = new Usersignup;
  modalCollapsed = true;

  faQuestion = faQuestion;

  constructor(private service: SignupService, private router: Router, private activatedRoute : ActivatedRoute, private modalService: NgbModal) { 
	
  }

  ngOnInit(): void {
	this.activatedRoute.queryParams.subscribe(params => {
		this.email!.setValue(params.email || "");
		this.login!.setValue(params.email || "");
	});
  }

  signUpForm = new FormGroup({
	email: new FormControl("", {
		validators : [
			Validators.required,
			Validators.pattern(emailRegex)
			],
		updateOn: 'blur'
		}
	),
	nam: new FormControl("", {
		validators : [
			Validators.required,
			Validators.pattern(namRegex)
			],
		updateOn: "blur"
		}
	),
	login: new FormControl("", {
		validators : [
			Validators.required,
			Validators.pattern(loginRegex)
			],
		updateOn: "blur"
		}
	),
	termsAndPrivacy: new FormControl(false, {
		validators : [
			Validators.requiredTrue
			]
		}
	),
	password: new FormControl("", {
		validators : [
			Validators.required,
			Validators.pattern(passwordRegex)
			],
		updateOn: "blur"
		}
	),
	passwordValidation : new FormControl("", {
			validators : [
			Validators.required
			],
		updateOn: "blur"
		}
	)
  }, checkSameValidator("password", "passwordValidation"))	

    private newMethod() {
        return this.email;
    }

  get email() {return this.signUpForm.get('email')}
  get nam() {return this.signUpForm.get('nam')}
  get login() {return this.signUpForm.get('login')}
  get password() {return this.signUpForm.get('password')}
  get passwordValidation() {return this.signUpForm.get('passwordValidation')}

  onClickSubmit(){
	if(this.signUpForm.valid){
		this.error = "";
		let model = new Usersignup()
		model.email = this.email!.value;
		model.password = this.password!.value;
		model.login = this.login!.value;
		model.nam = this.nam!.value;
		this.service.signup(model).subscribe(
			result =>{
				this.router.navigate(['/app/home'])			
			}, error=>{
				this.error = error;
			}
		);
	}else{
		this.signUpForm.markAllAsTouched();
	}
  }

  openModal(){
	const modalRef = this.modalService.open(PasswordModalComponent);
  }

}