import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private title: Title, private meta: Meta, private router: Router,	private activateRoute: ActivatedRoute) { }

  private updateTitle(title: string) {
    this.title.setTitle(title);
  }

  private updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  private updateKeywords(keywords: string){
	this.meta.updateTag({ name: 'keywords', content: keywords })
  }

  updateTag(title?: string){
	if (!title) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activateRoute),
                map((route) => {
                    while (route.firstChild) { route = route.firstChild; }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.parent && route.parent.snapshot.data['title']? route.parent.data : route.data)).subscribe((event) => {
                    this.updateTitle(event['title']);
					this.updateKeywords(event['keywords']);
        			this.updateDescription(event['title'] + event['description'])
                });
    } else {
        this.title.setTitle(title);
    }
  }
}
