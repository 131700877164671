import { Dependent } from "./dependent";

export class User {
	
	login!: string;
	lastName!: string;
	firstName!: string; 
	email!: string;
	password!: string;
	telephone?: string;
	sex!: string;
	dateOfBirth?: Date;
	nam!: string;
	accountNonLocked!: number;
    id?: number;
}
