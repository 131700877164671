import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/app-config-service.service';
import { StorageService } from 'src/app/storage.service';

@Component({
  selector: 'app-loggedoutfooter',
  templateUrl: './loggedoutfooter.component.html',
  styleUrls: ['./loggedoutfooter.component.css']
})
export class LoggedoutfooterComponent implements OnInit {
	
  termsAndPrivacy = "txt.lbl.termsAndPrivacyFooter";

  constructor(private translate: TranslateService, private storage: StorageService, private configService: AppConfigService) { }

  version = "sofy " + this.configService.getVersion();
  ngOnInit(): void {
  }
  setLang(lang : string){
	this.translate.use(lang);	
	this.storage.setItem("lang", lang)
  }
}
