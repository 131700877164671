import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupComponent } from './loggedout/signup/signup.component';
import { LoginComponent } from './loggedout/login/login.component';
import { AuthGuard } from './loggedout/auth-guard';
import { LogoutComponent } from './loggedout/logout/logout.component';
import { RecoverComponent } from './loggedout/recover/recover.component';
import { ModifypasswordComponent } from './loggedout/recover/modifypassword/modifypassword.component';
import { NotfoundComponent } from './loggedout/notfound/notfound.component';
import { PrivacyComponent } from './loggedout/privacy/privacy.component';
import { TermsComponent } from './loggedout/terms/terms.component';

const routes: Routes = [
	{ 
		path: 'app',
		loadChildren: () => import('./sofy/sofy.module').then(m => m.SofyModule),
		canActivate: [AuthGuard]
	},
	{ 
		path: 'rvsp',
		loadChildren: () => import('./rvsp/rvsp.module').then(m => m.RvspModule)
	},
	{
		path: 'nosession',
		loadChildren: () => import('./sofyexpress/sofyexpress.module').then(m => m.SofyexpressModule)
	},
	{ 
		path: 'signup', 
		component: SignupComponent,
	 	data: {
          title: 'Signup/Sofy',
          description:', Plateforme de prise de rendez-vous médical Sofy. Permet la prise de rendez-vous rapide partout au Québec',
          keywords: 'Rendez-vous, médical, clinique, médecin, Québec, prendre, santé, covid, hub, sans rendez-vous'
        }
	},
	{
		path: 'login', 
		component: LoginComponent,
		data: {
          title: 'Login/Sofy',
          description:', Plateforme de prise de rendez-vous médical Sofy. Permet la prise de rendez-vous rapide partout au Québec',
          keywords: 'Rendez-vous, médical, clinique, médecin, Québec, prendre, santé, covid, hub, sans rendez-vous'
        } 
	},
	{ path: 'logout', component: LogoutComponent },
	{ path: 'recover', component: RecoverComponent },
	{ path: 'recover/do', component: ModifypasswordComponent },
	{ path: '404', component: NotfoundComponent },
	{ path: 'privacy', component: PrivacyComponent },
	{ path: 'terms', component: TermsComponent },
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'sofy', redirectTo: 'login', pathMatch: 'full' },
	{ path: '**', redirectTo: '/404'}
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabledBlocking' })
  ],
  exports: [
	RouterModule
  ]
})
export class AppRoutingModule { }
