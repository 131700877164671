import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private service: LoginService, private router: Router) { }

  ngOnInit(): void {
	this.service.logout().subscribe(
		data=>{
			let message = "login.successfulLogout";
			this.router.navigateByUrl('/login', {state: {message}});
		});
  }

}
