<app-loggedoutheader></app-loggedoutheader>
<div id="container" class="container" style="margin-bottom: 2em">
	<br/>
	<div id="page">
	<div class="form-group  em">
		<div *ngIf="error" class="alert alert-danger col-12">{{ error | translate }}</div>
	</div>
	<form class="form-horizontal card frm" method="post" [formGroup]="modifyForm">
		<div class="form-group">
			<label for="login1" class="col control-label required" style="text-align: left;">{{ "txt.lbl.loginId" | translate }}</label>
			<div class="col">
				<p style="font-size:1.4em;" class="form-control-static">{{ userLogin! }}</p>
			</div>
		</div>
		<div class="form-group" id="divNewPassword">
			<label for="newPassword" class="col" style="text-align: left;">{{ "user.modify.newPassword" | translate }}</label>
			<div class="col form-div">
				<fa-icon id="btnPasswordHelp" class="icon" (click)="openModal()" data-target="#myModal" [icon]="faQuestion"></fa-icon>
				<input path="newPassword" class="form-control" id="newPassword" type="password" placeholder='{{ "user.modify.newPassword" | translate }}' formControlName="password"/>
				<div *ngIf="password!.errors && (password!.dirty || password!.touched)" class="text-danger">{{ 'user.modify.invalidPassword' | translate }}</div>
			</div>
		</div>
		<div class="form-group" id="divConfirmNewPassword">
			<label for="confirmNewPassword" class="col" style="text-align: left;">{{ "user.modify.confirm" | translate }}</label>
			<div class="col form-div">
				<input path="confirmNewPassword" class="form-control" id="confirmNewPassword" type="password" placeholder='{{ "user.modify.confirmNewPassword" | translate }}' formControlName="passwordValidation"/>
			</div>
		</div>
		<div class="form-group" >
			<div style="float:right;">
				<a routerLink="/login" class="btn btn-default btn-md">{{ "txt.btn.cancel" | translate }}</a>
				<button class="btn btn-submit btn-submit" (click)="changePassword()">
					{{ "txt.btn.submit" | translate }}
				</button>
			</div>
			
		</div>
	</form>
	</div>
</div>

<app-loggedoutfooter></app-loggedoutfooter>