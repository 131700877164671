import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { LoginService } from './loggedout/login/login.service';
import { StorageService } from './storage.service';
import { SEOService } from './seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'angularapp';
  locale!: string;
  constructor(private translate: TranslateService, private loginService: LoginService, private storage: StorageService, private seoService: SEOService) {
    const lang = this.storage.getItem("lang");
	const navLang = navigator.language
	if(lang !== null){
		this.translate.use(lang!)
	} else if(navLang == 'en'){
		this.translate.use(navLang!);
		this.storage.setItem('lang', navLang);
	} else{
		this.translate.setDefaultLang('fr');
		this.storage.setItem('lang', 'fr');
	}
	this.seoService.updateTag();
  }

  getUserName(){
     return sessionStorage.getItem("username");
  }
  onLogOut(){
    this.loginService.logout();
  }
}
