import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PrivacyComponent implements OnInit {

  errorMsg = "";
  privacyText?: string | null;

  anchorList = ['general', 'responsable', 'loivigueur', 'renseignement', 'metadonne', 'donneApp', 'communication', 'partageRenseignement', 
			'hub', 'destructionDonne', 'localisationDonne', 'partiesPrenantes']

  constructor(private service: LoginService, private viewportScroller: ViewportScroller, private sanitizer: DomSanitizer,
		private elementRef: ElementRef, private activatedRoute : ActivatedRoute) {}

   ngOnInit(): void {
	this.activatedRoute.queryParams.subscribe(params => {
		let lang = params.lang;
			this.service.getPrivacy(lang).subscribe(
			data=>{
				this.privacyText = this.sanitizer.sanitize(SecurityContext.HTML, data);
			}, error =>{
				this.errorMsg = error;
			}
		)	
	});
  }

  scrollTo(id: string){
	this.viewportScroller.scrollToAnchor(id);
  }

  ngAfterViewChecked (){
    for(let i = 0; i < this.anchorList.length; i++){
		if(this.elementRef.nativeElement.querySelector('#'+this.anchorList[i])){
			this.elementRef.nativeElement.querySelector('#'+this.anchorList[i])
				.addEventListener("click", this.scrollTo.bind(this, this.anchorList[i] + "_anchor"), false)
		}
	}
  } 

}
