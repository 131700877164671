import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from './config';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
	
  appConfig!: Config;
  private SETTINGS_LOCATION = "./assets/config.json";

  constructor(private handler: HttpBackend) { }

  loadAppConfig(){
	return new HttpClient(this.handler).get<Config>(this.SETTINGS_LOCATION)
			.toPromise()
			.then(data =>{
				this.appConfig = data;
			})
  }
  
  getConfig(){
	return this.appConfig
  }

  getVersion(){
	return this.appConfig.sofyVersion;
  }

  getSofyUrl(){
	return this.appConfig.baseUrl + "api/";
  }
  getRvspUrl(){
	return this.appConfig.baseUrl + "rvsp/api/"
  }
  getSofyexpressUrl(){
	return this.appConfig.baseUrl + "nosession/api"
  }

}
