import { HttpHandler, HttpEvent, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StorageService } from "src/app/storage.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor{
	
	constructor(private storage: StorageService){}
	
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//		if (isPlatformBrowser(this.platformId)) {
			if (this.storage.getItem('authToken') != null){
				const token : string  = this.storage.getItem('authToken')!;
		        req = req.clone({
			    	setHeaders: {
			       		'authToken': token ,
			    	},
			    });
			}
//		}
  	 	return next.handle(req);
    }
}
