import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplaceBreakline } from './pipe-function';
import { FormatTimePipe } from './pipe-function';



@NgModule({
  declarations: [ReplaceBreakline, FormatTimePipe],
  imports: [
    CommonModule
  ],
  exports:[ReplaceBreakline, FormatTimePipe]
})
export class PipeSofyModule { }
