import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { b64DecodeUnicode } from 'src/app/include/util/function';
import { LoginService } from './login.service';
import { Userlogin } from './userlogin';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private loginService: LoginService, private router: Router, private route: ActivatedRoute) { 
	this.userlogin = new Userlogin();
	this.msg = this.router.getCurrentNavigation()?.extras.state?.message || "";
	if(this.loginService.authError != ""){
		this.errorMsg = this.loginService.authError;
		this.loginService.authError = "";
	}
  }

  termsAndPrivacy = "txt.lbl.termsAndPrivacy";
  msg! : string;
  error! : string;
  email! : string;
  errorMsg = "";
  userlogin: Userlogin;
  returnUrl! : string;
  showTermsAndPrivacy = false;

  ngOnInit(): void {
	if(this.route.snapshot.queryParams['returnUrl']){
		this.returnUrl = b64DecodeUnicode(this.route.snapshot.queryParams['returnUrl']);
	}else{
		this.returnUrl = '/app/home';
	}
  }

  loginform = new FormGroup({
	username: new FormControl(null, Validators.required),
	password: new FormControl(null, Validators.required),
	termsAndPrivacy: new FormControl(null),
  });

  Login(){
	if(this.loginform.valid){
		this.userlogin.login = this.Username!.value;
		this.userlogin.password = this.Password!.value;
		this.error = "";
		this.msg = "";
		this.errorMsg = "";
		this.loginService.login(this.userlogin).subscribe(
			result =>{
				this.loginService.unreadCount().subscribe(
					result =>{					
						this.router.navigate([this.returnUrl])
					}, error=>{
						this.error = error;
					}
				);
						
			}, error=>{
				this.error = error;
			}
		);
	}else{
		this.loginform.markAllAsTouched();
	}
	
  }

  get Username(){  
      return this.loginform.get('username');  
  }  
  
  get Password(){  
      return this.loginform.get('password');  
  }  

}
