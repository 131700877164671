<div class="modal-header">
	<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
	<h4 class="modal-title" id="myModalLabel">{{ 'user.modify.password.security' | translate }}</h4>
</div>
<div class="modal-body">
	<ul class="text-info" style="list-style-type: circle; font-size: 1em; padding-left: 2em; margin-bottom: 0px;">
		<li>{{ 'user.modify.password.security1' | translate }}</li>
		<li>{{ 'user.modify.password.security2' | translate }}</li>
		<li>{{ 'user.modify.password.security3' | translate }}</li>
		<li>{{ 'user.modify.password.security4' | translate }}</li>
		<li>{{ 'user.modify.password.security5' | translate }}</li>
		<li>{{ 'user.modify.password.security6' | translate }}</li>
	</ul>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-default" (click)="activeModal.close()">{{ 'txt.close' | translate }} </button>
</div>
