<app-loggedoutheader></app-loggedoutheader>

<div id="container" class="container">
	<div id="page">
		<div class="card frm">
			<h2 class="title">{{ 'error.msg.pagenotfound' | translate}}</h2>
			<span style="margin-bottom:1em;">{{ 'error.msg.pagenotfound.msg' | translate}}</span>
			<div style="text-align:center;">
				<button (click)="back()" class="btn btn-primary btn-submit">{{ 'txt.goToHome' |translate }}</button>
			</div>
		</div>
	</div>
</div>

<app-loggedoutfooter></app-loggedoutfooter>
