import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordModalComponent } from 'src/app/include/password-modal/password-modal.component';
import { checkSameValidator } from 'src/app/include/util/function';
import { RecoverService } from '../recover.service';

@Component({
  selector: 'app-modifypassword',
  templateUrl: './modifypassword.component.html',
  styleUrls: ['./modifypassword.component.css']
})
export class ModifypasswordComponent implements OnInit {

  error = "";
  userLogin = "";

  faQuestion = faQuestion;

  passwordRegex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9_]).{10,40}";

  constructor(private service: RecoverService, private router: Router, private route: ActivatedRoute, private modalService: NgbModal) {
	this.route.queryParams
      .subscribe(params => {
        this.service.key = params.key;
		this.service.getUser().subscribe(
				result =>{
				this.userLogin = result.login;	
				}, error=>{
					this.error = error;
				}
			);
      })
  }

  ngOnInit(): void { }

  modifyForm = new FormGroup({
		password: new FormControl("", {
			validators : [
				Validators.required,
				Validators.pattern(this.passwordRegex)
				],
			updateOn: "blur"
			}
		),
		passwordValidation : new FormControl("", {
				validators : [
				Validators.required
				],
			updateOn: "blur"
			}
		)
	}, checkSameValidator('password', 'passwordValidation'))	

  get password() {return this.modifyForm.get('password')}
  get passwordValidation() {return this.modifyForm.get('passwordValidation')}

  changePassword(){
	if(this.modifyForm.valid){
		this.service.modifyPassword(
		this.password!.value, 
		this.passwordValidation!.value
		).subscribe(
			result =>{
				this.router.navigate(['/app/home'])			
			}, error=>{
				this.error = error;
			}
		);
	}else{
		this.modifyForm.markAllAsTouched();
	}
  }

  openModal(){
	const modalRef = this.modalService.open(PasswordModalComponent);
  }
}