import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, tap } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config-service.service';
import { HandleError } from 'src/app/include/util/function';
import { AppointmentService } from 'src/app/sofy/appointment/appointment.service';
import { StorageService } from 'src/app/storage.service';
import { Userlogin } from './userlogin';

@Injectable({
  providedIn: 'root'
})
  export class LoginService {
	
  private baseUrl;
  gotUnread = false;
  authError = "";
  

  constructor(private http: HttpClient, private storage: StorageService, private config: AppConfigService, 
			private dependantService: AppointmentService, private modalService: NgbModal ) {
	this.baseUrl = this.config.getSofyUrl();
  }
  
  login(user: Userlogin){
	let header = new HttpHeaders({
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin':'*'
	});
	let options = { headers: header }
	let param = { "username" : user.login, "password" : user.password}
	let url = this.baseUrl + "login";
	return this.http.post<string>(url, param, options)
		.pipe(catchError(HandleError),
			tap(response =>{
				this.storage.setItem("user", user.login);
			})
		);
  }

  logout(){
	let url = this.baseUrl + "logout";
	return this.http.get(url, {withCredentials: true})
		.pipe(catchError(HandleError),
			tap(response =>{
				this.modalService.dismissAll();
				this.dependantService.clearDependent();
				const lang = this.storage.getItem("lang");		
				this.storage.clear();
				this.storage.setItem("lang", lang);
			})
		);
  }

  unreadCount(){
	let url = this.baseUrl + "mailbox/unread";
	return this.http.post<number>(url, null)
			.pipe(catchError(HandleError),
				tap(response =>{
					this.storage.setItem("unread", response.toString())
				})
			);	
  }

  getPrivacy(lang: string){
	let url = this.baseUrl + "login/privacy";
//	let lang = this.storage.getItem("lang");
	if(!lang)lang = "fr";
	return this.http.get(url, {params : { lang }, responseType: 'text' } )
			.pipe(catchError(HandleError),
				tap(response =>{
					
				})
			);	
  }

  getTerms(lang: string){
	let url = this.baseUrl + "login/terms";
//	let lang = this.storage.getItem("lang");
	if(!lang)lang = "fr";
	return this.http.get(url, {params : { lang }, responseType: 'text' })
			.pipe(catchError(HandleError),
				tap(response =>{
					
				})
			);	
  }

  getLoginString(){
	return this.storage.getItem("user");
  }

  isLoggedIn(): boolean {
    return this.storage.getItem('user') !== null;
  }

}
