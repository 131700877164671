<app-loggedoutheader></app-loggedoutheader>
<div id="container" class="container">
	<div id="page" class="row">
		<div class="card frm">
			<h2 class="title">{{ 'login.welcome' | translate}}</h2>
			<form [formGroup]="loginform" #LoginInformation (ngSubmit)="Login()" class="form-horizontal">
				<div class="form-group  em">
					<div *ngIf="errorMsg" class="alert alert-danger col-12">{{ errorMsg | translate }}</div>
					<div *ngIf="error" class="alert alert-danger col-12">{{ error | translate }}</div>
					<div *ngIf="msg" class="alert alert-success col-12">{{ msg | translate}}</div>
				</div>
				<div class="form-group">
					<label class="control-label" for="username">{{ 'login.loginIdOrEmail' | translate }} : </label> 
					<div> 
						<input type="email" class="form-control inputLogin" value="{{ email }}"  formControlName="username" name="username" id="username"/>
						<div class="alert alert-danger" *ngIf="loginform.controls['username'].invalid && (loginform.controls['username'].dirty || loginform.controls['username'].touched)" style="margin-top:5px;">
			            	{{ 'errors.cannotBeEmpty' | translate }}
			           	</div>
					</div>
				</div>
				<div class="form-group" style="margin-top: 35px;">
					<label class="control-label" path="Password" for="password">{{ 'login.password' | translate }} : </label>
					<div> 
						<input type = "password" class="form-control inputLogin" formControlName="password" name="password" id="password"/>
						<div class="alert alert-danger" *ngIf="loginform.controls['password'].invalid && (loginform.controls['password'].dirty || loginform.controls['password'].touched)" style="margin-top:5px;">
			            	{{ 'errors.cannotBeEmpty' | translate }}
			           	</div>
					</div>
				</div>
				<div class="form-group" *ngIf="showTermsAndPrivacy">
					<input type="checkbox" [checked]="false" class="mobile-cb" formControlName="termsAndPrivacy">
					<span [innerHTML]="termsAndPrivacy | translate"></span>
					<div *ngIf="loginform.controls['termsAndPrivacy'].invalid && (loginform.controls['termsAndPrivacy'].dirty || loginform.controls['termsAndPrivacy'].touched)" class="text-danger">
						{{ 'errors.acceptTermsAndPrivacy' | translate }}
					</div>
				</div>
				<div class="form-group">
					<a routerLink='/recover'> {{ 'login.forgotPassword' | translate }}</a>
					<a href="https://www.ofys.ca/fr/sofy.html" target="_blank" class="guide">{{ 'txt.guide' | translate }}</a>
				</div> 
				<div class="form-group">
					<div class="col" style="text-align:center;">
						<button type="submit" class="btn btn-submit col-md-6" style="width:100%; margin:20px 0;">
							{{ 'login.login' | translate }}
						</button>
					</div>
				</div>
				<div class="form-group text-center">
					<a class="signupLink" routerLink='/signup'>{{ 'login.signUp' | translate }}</a>
				</div>
				<div class="form-group text-center">
					<a class="signupLink" routerLink='/rvsp/auth'>{{ 'login.hub' | translate }}</a>
				</div>
				<div class="alert alert-info col-lg-12 securityMsg">{{ 'txt.securiteMessage' | translate }}</div>
			</form>
		</div>
	</div>
</div>
<app-loggedoutfooter></app-loggedoutfooter>