import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/storage.service';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {

  constructor(private service: LoginService, private router: Router) { }

  ngOnInit(): void {
  }

  back(){
	if(this.service.isLoggedIn()){
		this.router.navigate(["/app/home"])
	}else{
		this.router.navigate(["/login"])
	}
  }

}
