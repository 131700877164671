import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, tap } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config-service.service';
import { HandleError, HandleErrorText } from 'src/app/include/util/function';
import { User } from 'src/app/sofy/user';

@Injectable({
  providedIn: 'root'
})
export class RecoverService {

  private baseUrl;

  key!: string;
  user: User = new User

  constructor(private http: HttpClient, private config: AppConfigService, private translate: TranslateService) {
	this.baseUrl = this.config.getSofyUrl() + "recover";
  }

  recoverPassword(email : string, nam : string){
	let model = {
		"email" : email,
		"nam" : nam,
		"lang" : this.translate.currentLang
	}
	return this.http.post(this.baseUrl, model, { responseType: 'text' })
		.pipe(catchError(HandleErrorText),
			tap(response =>{
				
			})
		);
  }

  getUser(){
	let url = this.baseUrl + "/user";
	return this.http.post<User>(url, this.key)
		.pipe(catchError(HandleError),
			tap(response =>{
				this.user = response;
			})
		);
  }


  modifyPassword(password: string, confirmPassword: string){
	let model = {
		"username" : this.user.login,
		"newPassword" : password,
		"confirmNewPassword" : confirmPassword,
		"key" : this.key
	}
	let url = this.baseUrl + "/modify";
	return this.http.post(url, model)
		.pipe(catchError(HandleError),
			tap(response =>{
				sessionStorage.setItem("user", this.user.login);
			})
		);
  }
}
