import { HttpClient, HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { LoggedoutfooterComponent } from './include/loggedoutfooter/loggedoutfooter.component';
import { LoggedoutheaderComponent } from './include/loggedoutheader/loggedoutheader.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SignupComponent } from './loggedout/signup/signup.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './loggedout/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './loggedout/auth-guard';
import { SessionInterceptor } from './sofy/session-interceptor';
import { NgbCollapseModule, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LogoutComponent } from './loggedout/logout/logout.component';
import { RecoverComponent } from './loggedout/recover/recover.component';
import { ModifypasswordComponent } from './loggedout/recover/modifypassword/modifypassword.component';
import { AppConfigService } from './app-config-service.service';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from  "@angular/common/locales/fr";
import localeEn from  "@angular/common/locales/en";
import { PipeSofyModule } from './include/util/pipe/pipe-sofy.module';
import { PasswordModalComponent } from './include/password-modal/password-modal.component';
import { RvspComponent } from './rvsp/rvsp.component';
import { AuthInterceptor } from './rvsp/appointment/auth-interceptor';
import { StorageService } from './storage.service';
import { NotfoundComponent } from './loggedout/notfound/notfound.component';
import { SofyexpressComponent } from './sofyexpress/sofyexpress.component';
import { PrivacyComponent } from './loggedout/privacy/privacy.component';
import { TermsComponent } from './loggedout/terms/terms.component';
import { RouterModule } from '@angular/router';
import { ConfirmDependentComponent } from './include/confirm-dependent/confirm-dependent.component';
import { LockedDependentComponent } from './include/locked-dependent/locked-dependent.component';

registerLocaleData(localeEn, "en")
registerLocaleData(localeFr, "fr")

export const configFactory = (configService: AppConfigService) => {
	return () => configService.loadAppConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    LoggedoutfooterComponent,
    LoggedoutheaderComponent,
    SignupComponent,
    LoginComponent,
    LogoutComponent,
	RecoverComponent,
	NotfoundComponent,
	ModifypasswordComponent,
 	PasswordModalComponent,
 	RvspComponent,
 	SofyexpressComponent,
	PrivacyComponent,
 	TermsComponent,
 	ConfirmDependentComponent,
 	LockedDependentComponent,
  ],
  imports: [
    BrowserModule,
	RouterModule,
	FontAwesomeModule,
	HttpClientModule,
	ReactiveFormsModule,
	BrowserAnimationsModule,
	FormsModule,
    TranslateModule.forRoot({
	  defaultLanguage: 'fr',
	  loader:{
		provide: TranslateLoader,
		useFactory: HttpLoaderFactory,
		deps: [HttpClient]
	  }
    }),
    FontAwesomeModule,
    AppRoutingModule,
	HttpClientXsrfModule.withOptions(
		{
			cookieName: 'XSRF-TOKEN'
		}
	),
	NgbDatepickerModule,
	NgbCollapseModule,
 	NgbModule,
	PipeSofyModule,
  ],
  providers: [ 
	DatePipe,
	AuthGuard,
	{
		provide: HTTP_INTERCEPTORS,
		useClass: SessionInterceptor,
		multi: true
	},
	AppConfigService,
	{
		provide: APP_INITIALIZER,
		multi: true,
		deps: [AppConfigService],
		useFactory: configFactory
	},
	{
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
      deps    : [StorageService],
    },
  ],
  exports:[TranslateModule],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
