import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { StorageService } from "../storage.service";

@Injectable()
export class AuthGuard {
	
	constructor(private router: Router, private storage: StorageService){}
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
		if(this.storage.getItem('user')){
			return true;
		}
		this.router.navigate(["/login"], { queryParams: { returnUrl: btoa(state.url) }});
		return false;
	}
}
