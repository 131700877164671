import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TermsComponent implements OnInit {
	
  errorMsg = "";
  termsText!: SafeHtml;

  anchorList = ['general', 'acceptation', 'consentementCollecte', 'consentementComm', 'protectionRenseignement', 'motDePasse', 'messagerie', 'compteDelegue', 
			'consentementPersonneConsernee', 'utilisationCompteDelegue', 'tierce', 'majRenseignement', 'rvEnLigne', 'modifCondition', 'contact']

  constructor(private service: LoginService, private viewportScroller: ViewportScroller, private sanitizer: DomSanitizer,
		private elementRef: ElementRef, private activatedRoute : ActivatedRoute) { }

  ngOnInit(): void {
	this.activatedRoute.queryParams.subscribe(params => {
		let lang = params.lang;
			this.service.getTerms(lang).subscribe(
			data=>{
				this.termsText = this.sanitizer.bypassSecurityTrustHtml(data);
			}, error =>{
				this.errorMsg = error;
			}
		)	
	});	
  }

  scrollTo(id: string){
	this.viewportScroller.scrollToAnchor(id);
  }

  ngAfterViewChecked (){
    for(let i = 0; i < this.anchorList.length; i++){
		if(this.elementRef.nativeElement.querySelector('#'+this.anchorList[i])){
			this.elementRef.nativeElement.querySelector('#'+this.anchorList[i])
				.addEventListener("click", this.scrollTo.bind(this, this.anchorList[i] + "_anchor"), false)
		}
	}
  } 

}
